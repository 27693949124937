import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { vegaAPI } from '../api';
import { jwtDecode } from "jwt-decode";
import 'bootstrap-icons/font/bootstrap-icons.css';

export const Devices = ({ token }) => {

  const user = jwtDecode(token).user;

  const [vegas, setVegas] = useState(null);
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    vegaAPI
      .get('/api/getVegas', { headers: { authorization: token } })
      .then(res => setVegas(res.data))
      .catch(err => {
        setVegas([]);
        console.log(err);
      });
  }, [token]);

  useEffect(() => {
    if (vegas && vegas.length) {
      vegas.forEach((vega, index) => {
        fetch(`${vega.url}/ping?target=${vega.url_router}`, { headers: { 'ngrok-skip-browser-warning': true } })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            const vegaLabel = document.getElementById(`ghlabel${vega.device_id}`);
            const vegaMain = document.getElementById(`gh${vega.device_id}`);
            const vegaInfo = document.getElementById(`ghinfo${vega.device_id}`);
            const botInfo = document.getElementById(`botinfo${vega.device_id}`);
            const batInfo = document.getElementById(`batinfo${vega.device_id}`);

            if (!vegaLabel || !vegaMain || !vegaInfo) {
              return;
            }

            if (data.dev_id !== vega.device_id) {
              vegaLabel.innerHTML = 'ERROR';
              vegaLabel.classList.remove('waiting');
              vegaLabel.classList.add('error');
              console.log('Error: Device ID mismatch', vega.device_id);
            }
            else {
              vegaLabel.innerHTML = data.auto_run ? 'RUNNING...' : 'ONLINE';
              vegaLabel.classList.remove('waiting');
              vegaLabel.classList.add('ready');
              vegaMain.classList.add('clickable');
              vegaMain.onclick = () => {
                navigate(`/monitor/${vega.device_id}`);
              };
              botInfo.innerHTML = '';
              if (data.description) {
                botInfo.innerHTML += `<span><strong>Serial:</strong> ${data.description}</span>`;
              }
              botInfo.innerHTML += `<span><strong>Location:</strong> ${data.gh_name} - ${data.track_name}</span>`;
              if (data.battery_lvl) {
                const bat_status = data.battery_lvl > 70 ? 'text-success' : data.battery_lvl > 50 ? 'text-warning' : 'text-danger';
                const bat_icon = data.battery_lvl > 70 ? 'bi-battery-full' : data.battery_lvl > 50 ? 'bi-battery-half' : 'bi-battery';
                batInfo.innerHTML = `
                  <span><strong class="align-middle">${data.battery_lvl}% </strong><i class="bi ${bat_icon} icon ${bat_status} align-middle bat-icon"></i></span>
                `;
              }
              botInfo.classList.add('device-info');
              batInfo.classList.add('device-info');
              vegaInfo.classList.add('vega-info');
              vegaInfo.classList.remove('hide');
            }
          })
          .catch(error => {
            const vegaLabel = document.getElementById(`ghlabel${vega.device_id}`);
            if (vegaLabel)
              vegaLabel.innerHTML = 'OFFLINE';
          });
      })
    }
  }, [vegas, navigate])

  useEffect(() => {
    if (vegas && vegas.length) {
      vegas.forEach(vega => {
        const vegaMain = document.getElementById(`gh${vega.device_id}`);
        if (vegaMain) {
          if (vega.description.toLowerCase().includes(filter.toLowerCase())) {
            vegaMain.classList.remove('hide');
          }
          else {
            vegaMain.classList.add('hide');
          }
        }
      });
    }
  }, [filter, vegas])

  return (
    <>
      <header>
        <div className="container">
          <a href="/" title="Go Main"><img className="title-wide" src="/grodi_logo.png" alt="GrodiTech Remote" /></a>
          <a href="/" title="Go Main"><img className="title-portrait" src="/grodi_logo_corto.png" alt="GrodiTech Remote" /></a>
          <span className="text-title">REMOTE</span>
          <span className="logout">
            <span className="username">{user.name}</span>
            <a href="/logout" title="Logout">X</a>
          </span>
        </div>
      </header>
      <div className="container">
        <div className="index-title">
          <span className='ps-1'>Devices</span>
          {vegas && vegas.length !== 0 &&
            <input
              className='filter-dev'
              placeholder='Write to filter devices'
              value={filter}
              onChange={e => setFilter(e.target.value)}
            />
          }
        </div>


        {vegas && vegas.length !== 0 &&
          <ul className="mainlist">
            {vegas.map(vega => (
              <li
                id={`gh${vega.device_id}`}
                className={`itemlist `}
                key={vega.device_id}
              >
                <div className="itemtitle">
                  {vega.description}
                  <span id={`ghlabel${vega.device_id}`} className="waiting labellist">WAITING</span>
                </div>
                <div id={`ghinfo${vega.device_id}`} className='hide'>
                  <div id={`botinfo${vega.device_id}`}></div>
                  <div id={`batinfo${vega.device_id}`} className='align-end'></div>
                </div>
              </li>
            ))
            }
          </ul>
        }

        {vegas && vegas.length === 0 &&
          <div className='mainlist'>
            <div className='text-center py-2'>No tienes dispositivos VEGA accesibles</div>
          </div>
        }

        {!vegas &&
          <div className='mainlist'>
            <div className='text-center py-2'>Cargando...</div>
          </div>
        }

      </div>
      <footer>
        <div>&copy; GrodiTech 2024</div>
      </footer>
    </>
  );
};
