import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { vegaAPI } from '../api';
import { jwtDecode } from "jwt-decode";
import axios from 'axios';

export const Monitor = ({ token }) => {

  const user = jwtDecode(token).user;
  const { device_id } = useParams();
  const [vega, setVega] = useState();
  const [data, setData] = useState();
  const [cameras, setCameras] = useState(0);
  const [days, setDays] = useState(1);
  const [time, setTime] = useState('09:00');
  const [log, setLog] = useState('');
  const [disable, setDisable] = useState(true);
  const [fullscreen, setFullscreen] = useState(false);
  const [program, setProgram] = useState();
  const [program_2, setProgram_2] = useState();
  const navigate = useNavigate();


  const deviceDetector = () => {
	  var ua = navigator.userAgent.toLowerCase();
	  var detect = (s) => {
	  	if(s===undefined)s=ua;
	  	else ua = s.toLowerCase();
	  	if(/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(ua))
        return 'tablet';
      else if(/(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(ua))            
        return 'phone';
      else return 'desktop';
    };

    return {
      device:detect(),
      detect:detect,
      isMobile:((detect()!='desktop')?true:false),
      userAgent:ua
    };
  };

  const formatBytesUnit = (size) => {
    if (size > 1024*1024*1024)
        return (size/(1024*1024*1024)).toFixed(2) + ' GB';
    if (size > 1024*1024)
        return (size/(1024*1024)).toFixed(2) + ' MB';
    if (size > 1024)
        return (size/1024).toFixed(2) + ' KB';
    return size + ' B' 
  };

  const calculateVoltage = (lvl, max, min) => {
    return (lvl * (max - min)/100 + min).toFixed(1);
  };

  const setInterval = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('program', program_2);
    data.append('time', time);
    data.append('target', vega.url_router);

    axios
      .post(`${vega.url}/setjob`, data, { headers: {'content-type': 'multipart/form-data', 'ngrok-skip-browser-warning': true}})
        .then((resp)=>{
            if (resp.data === 'done')
              window.location.reload();
            if (resp.data === 'already')
              alert('Time already scheduled');
        })
        .catch((error)=>{
            console.log(error);
        });
  };

  const delSchedule = (e) => {
    e.preventDefault();
    const [prog, tim] = e.currentTarget.id.split('@');
    const data = new FormData();
    data.append('program', prog);
    data.append('time', tim);
    data.append('target', vega.url_router);

    axios
      .post(`${vega.url}/deljob`, data, { headers: {'content-type': 'multipart/form-data', 'ngrok-skip-browser-warning': true}})
        .then((resp)=>{
            if (resp.data === 'done')
              window.location.reload();
            if (resp.data === 'notfound')
              alert('Schedule not found');
        })
        .catch((error)=>{
            console.log(error);
        });
  };

  const toggleAuto = () => {
    const auto = document.getElementById('auto');
    const data = new FormData();
    data.append('program', program);

    axios
      .post(`${vega.url}/toggleauto?target=${vega.url_router}`, data, { headers: {'content-type': 'multipart/form-data', 'ngrok-skip-browser-warning': true}})
        .then(response => {
          if (!auto) return;
          if (response.data === 'auto'){
            auto.classList.add('green');
            auto.innerHTML = 'ON';
          }
          else {
            auto.classList.remove('green');
            auto.innerHTML = 'OFF';
          }
        })
        .catch(error => {
          auto.classList.add('red');
          auto.innerHTML = 'ERROR';
        });
  }

  const selectCam = (e) => {
    axios
      .get(`${vega.url}/cameras/${e.currentTarget.value}?target=${vega.url_router}`, { headers: { 'ngrok-skip-browser-warning': true}})
        .then((resp)=>{
            console.log(resp.data);
        })
        .catch((error)=>{
            console.log(error);
        });
  }

  const changeState = (newClass, message) => {
    var state = document.getElementById('state');
    state.innerHTML = message;
    state.classList.forEach( (value) => {
      state.classList.remove(value);
    });
    state.classList.add(newClass);
  }

  const sendCommand = (message, target) => {
    const data = new FormData();
    data.append('command', message);
    data.append('target', target);
    axios
      .post(`${vega.url}/echo`, data, { headers: { 'ngrok-skip-browser-warning': true}})
      .then(res => {
          setLog(res.data.response);
          const comm = res.data.response.split(' ');
          switch (comm[0]) {
              case 'MANUAL':
                  if (comm.length > 1) {
                      if (comm[1] === 'ON') {
                          let button = document.getElementById('toggle_en');
                          button.classList.remove('motordisabled');
                          button.classList.add('motorenabled');
                          button.innerHTML = 'DISABLE';
                      }
                      else if (comm[1] === 'OFF') {
                          let button = document.getElementById('toggle_en');
                          button.classList.remove('motorenabled');
                          button.classList.add('motordisabled');
                          button.innerHTML = 'ENABLE';
                      }
                  }
                  setDisable(false);
                  break;
              case 'ON':
                  let button = document.getElementById('toggle_en');
                  button.classList.remove('motordisabled');
                  button.classList.add('motorenabled');
                  button.innerHTML = 'DISABLE';
                  break;
              case 'OFF':
                  let buttoff = document.getElementById('toggle_en');
                  buttoff.classList.remove('motorenabled');
                  buttoff.classList.add('motordisabled');
                  buttoff.innerHTML = 'ENABLE';
                  break;
              case 'EXIT_M':
                  window.location.href = '/';
                  break;
              case 'BUSY':
                  setTimeout(
                      function(){
                          window.location.reload();
                      }, 5000);
                  break;
              default:
                  break;
          }
          changeState('ready', 'ONLINE');
          setDisable(false);
      })
      .catch(error => {
          setLog('Error: ' + error);
          changeState('error', 'ERROR');
          setDisable(false);
          return;
      });
  };

  const handleCommand = (e) => {
    e.preventDefault();
    const command = e.currentTarget.value;
    changeState('waiting', 'WAITING');
    setDisable(true);
    sendCommand(command, vega.url_router);
  }

  useEffect(() => {
    vegaAPI
      .get(`/api/getOneVega/${device_id}`, { headers: { authorization: token } })
      .then(res => {
        if (res.data.length === 0)
          return navigate('/error');
        setVega(res.data[0]);
      })
      .catch(err => navigate('/error'))
  }, [device_id, navigate, token]);

  useEffect(() => {
    if (!vega) return;

    const stream = document.getElementById('streamsrc');
    if (stream != null) {
      stream.onerror = () => {
        stream.src = '/notavailable.png'
        window.screen.orientation.onchange = null;
      };
    }

    axios
      .get(`${vega.url}/ping?full&target=${vega.url_router}`, { headers: { 'ngrok-skip-browser-warning': true}})
      .then(response => {
        setData(response.data);
      })
      .catch(error => console.error(error));
  }, [vega]);

  useEffect(() => {
    if (!data) return;

    const state = document.getElementById('state');
    const numCams = data.cameras ?? 1;
    const auto = document.getElementById('auto');
    const disk_usage = ((data.disk_space - data.free_space - data.data_size)/data.disk_space)*100;
    const data_usage = ((data.disk_space - data.free_space)/data.disk_space)*100;
    var ratio = data.free_space/data.disk_space;
    const freespace = document.getElementById('freespace');
    const datasize = document.getElementById('datasize');
    const program_list = document.getElementById('programlist');
    const set_program = document.getElementById('setprogram');

    document.documentElement.style.setProperty('--disk-used', disk_usage.toFixed(1)+'%');
    document.documentElement.style.setProperty('--data-used', data_usage.toFixed(1)+'%');
    document.documentElement.style.setProperty('--battery-lvl', data.battery_lvl+'%');

    setCameras(numCams);

    if (data.program_list) {
      setProgram(data.cur_program);
      setProgram_2(data.cur_program);
      program_list.innerHTML = '';
      set_program.innerHTML = '';
      data.program_list.forEach((program, index) => {
        program_list.innerHTML += `<option value="${program}">${program.charAt(0).toUpperCase()+program.slice(1)}</option>`;
        set_program.innerHTML += `<option value="${program}">${program.charAt(0).toUpperCase()+program.slice(1)}</option>`;
      });
      program_list.classList.remove('hide');
    }


    if (data.status === 'READY') {
      state.classList.remove('waiting');
      state.classList.add('ready');
      state.innerHTML = 'ONLINE';
    }
    else if (Date.status === 'BUSY') {
      state.innerHTML = 'BUSY';
    }

    if (data.auto_run) {
      auto.classList.add('green');
      auto.innerHTML = 'ON';
    }
    else {
      program_list.disabled = false;
    }

    if (ratio <= 0.1) {
      freespace.classList.add('red');
    }
    else if (ratio >= 0.5) {
      freespace.classList.add('green');
    }
    ratio = data.data_size/data.disk_space;
    if (ratio >= 0.1) {
      datasize.classList.add('red');
    }

  }, [data]);

  useEffect(() => {
    if (!data) return;

    window.addEventListener('beforeunload', e =>{
      sendCommand('EXIT_A', vega.url_router);
    });
    sendCommand('MANUAL', vega.url_router);

    if (deviceDetector().detect() === 'phone') {
      window.screen.orientation.onchange = () => {
        if (window.screen.orientation.type === 'landscape-primary') {
          document.documentElement.requestFullscreen();
          setFullscreen(true);
        }
          
        else {
          document.exitFullscreen()
          setFullscreen(false);
        }
          
      }
    }
  }, [data, vega])

  return (
    <>
      <header>
        <div className="container-wide">
          <a href="/" title="Go Main"><img className="title-wide" src="/grodi_logo.png" alt="GrodiTech Remote" /></a>
          <a href="/" title="Go Main"><img className="title-portrait" src="/grodi_logo_corto.png" alt="GrodiTech Remote" /></a>
          <span className="text-title">REMOTE</span>
          <span className="logout">
            <span className="username">{user.name}</span>
            <a href="/logout" title="Logout">X</a>
          </span>
        </div>
      </header>
      <div className="container-wide">

      {!vega &&
        <div className="mainlist">
          <div className="text-center py-2">Loading...</div>
        </div>
      }

      {vega &&
        <>
        <div className="ghinfo">
          <div className="device"><strong>{ vega._id }# { vega.description }</strong> :: <span id="state" className="waiting">WAITING</span></div>
          <div className="locat-class">
            <strong>Current location: </strong>
            <span id="location">{!data ? 'Greenhouse - Track' : `${data.gh_name} - ${data.track_name}`}</span>
          </div>
        </div>
        <div className="monitorgrid">
          <div className="buttonpad">
            <img id="streamsrc" src={`${ vega.url }/video_feed?target=${vega.url_router}`} alt="stream" className={fullscreen ? 'fullvideo' : 'minivideo'}/>
            <div id="campad" className={`${cameras === 1 ? 'hide' : ''} ${fullscreen ? 'fullcampad' : ''}`}>
              <button id="cam1" value={1} onClick={selectCam} disabled={cameras > 0 ? false : true}>CAM 1</button>
              <button id="cam2" value={2} onClick={selectCam} disabled={cameras > 1 ? false : true}>CAM 2</button>
              <button id="cam3" value={3} onClick={selectCam} disabled={cameras > 2 ? false : true}>CAM 3</button>
              <button id="cam4" value={4} onClick={selectCam} disabled={cameras > 3 ? false : true}>CAM 4</button>
            </div>
            <hr />
            <ul>
              <li>
                <span className="label">RUNNING:</span>
                <span id="auto" className="info">OFF</span>
                <button className="labellist" onClick={toggleAuto}>TOGGLE</button>
                <select
                  id='programlist'
                  className='select-list float-end hide'
                  disabled
                  value={program}
                  onChange={(e)=>setProgram(e.currentTarget.value)}>
                  <option>-</option>
                </select>
              </li>
            </ul>
            <hr />

            <div className={fullscreen ? 'fullcontrol' : 'manualgrid'}>
              <div><button disabled={disable} className="disabled" value={'FORWARD'} onClick={handleCommand} id="forward_b">FORWARD</button></div>
              <div><button disabled={disable} className="disabled" value={'BACKWARD'} onClick={handleCommand} id="backward_b">BACKWARD</button></div>
              <div><button disabled={disable} className="disabled" value={'STOP'} onClick={handleCommand} id="stop_b">STOP</button></div>
              <div><button disabled={disable} className="disabled" value={'TEST'} onClick={handleCommand} id="test_b">Get FRAME</button></div>
              <div><button disabled={disable} className="disabled motorenabled" value={'TOGGLE'} onClick={handleCommand}  id="toggle_en">DISABLE</button></div>
              {data && data.cameras === 1 &&
              <>
                <div><button disabled={disable} className="disabled" value={'CAM_S2_UP'} onClick={handleCommand} id="cam_left_b">Cam LEFT</button></div>
                <div><button disabled={disable} className="disabled" value={'CAM_S1_UP'} onClick={handleCommand} id="cam_up_b">Cam UP</button></div>
                <div><button disabled={disable} className="disabled" value={'CAM_S2_DW'} onClick={handleCommand} id="cam_right_b">Cam RIGHT</button></div>
                <div><button disabled={disable} className="disabled" value={'CAM_S1_DW'} onClick={handleCommand} id="cam_down_b">Cam DOWN</button></div>
              </>
              }
            </div>
            <div id="logdiv">
              <strong>Response: </strong>
              <span id="log">{log}</span>
            </div>
            <hr />
            <button id="manual_exit_b" className='w-100' value={'EXIT_M'} onClick={handleCommand}>EXIT</button>

          </div>
          <div className="buttonpad">
            <div className="chartwrapper">
              <div className="chart"></div>
              <div className="chartlegend">
                <span className='chart-title'>DISK USAGE</span>
                <hr />
                <div><span className="chartlgitem lga"></span>SYSTEM DATA</div>
                <div><span className="chartlgitem lgb"></span>VEGA DATA</div>
                <div><span className="chartlgitem lgc"></span>FREE SPACE</div>
              </div>
            </div>
            <hr />
            <ul>
              <li>
                <span className="label">DISK SPACE:</span><span id="diskspace" className="info">{!data ? '' : formatBytesUnit(data.disk_space)}</span>
              </li>
              <li>
                <span className="label">FREE SPACE:</span><span id="freespace" className="info">{!data ? '' : formatBytesUnit(data.free_space)}</span>
              </li>
              <li>
                <span className="label">DATA SIZE:</span><span id="datasize" className="info">{!data ? '' : formatBytesUnit(data.data_size)}</span>
              </li>
              <li>
                <span className="label">PENDING FILES:</span><span id="pending" className={`info ${!data ? '' : data.pending_img !== 0 ? 'red' : 'green'}`}>{!data ? '' : data.pending_img}</span>
              </li>
            </ul>
            <hr />
              <div className='row'>
                <div className='col-auto text-start'>
                  <span className="label">BATTERY:</span><span id="battery" className="info">{!data ? '' : calculateVoltage(data.battery_lvl, data.battery_max, data.battery_min)} V</span>
                </div>
                <div className='col ps-0'>
                  <div className="bat_total">
                    <div id="batt_bar" className="bat_lvl">
                      {!data ? '50%' : data.battery_lvl+'%'}
                    </div>
                  </div>
                </div>
              </div>
            <ul>
              <li>
                <span className="label">INTERNAL TEMP:</span><span id="temp" className="info">{!data ? '' : data.internal_temp}℃</span>
              </li>
            </ul>
            <hr />
            <div className='row'>
              <div className='col-auto text-start'>
                <span className="label">SCHEDULE:</span>
              </div>
            </div>
            <ul>
            {data && Object.keys(data.schedule).length === 0 && 
              <li className="infoform">
                No schedule set.
              </li>
            }
            {data && Object.keys(data.schedule).length !== 0 && 
              Object.keys(data.schedule).map((key, index) => (
                data.schedule[key].sort().map((time, index) => (
                  <li key={index} className="infoform">
                    <span className="sch-label">{key.charAt(0).toUpperCase()+key.slice(1)}</span> at 
                    <span className="sch-info">{time}</span>
                    <span id={`${key}@${time}`} className='del-button float-end' onClick={delSchedule}>X</span>
                  </li>
                ))
              ))
            }
            </ul>
            <div className="buttonpad2">
                <label htmlFor="days">Run</label>
                <select
                  id='setprogram'
                  className='select-list-2'
                  value={program_2}
                  onChange={(e)=>setProgram_2(e.currentTarget.value)}>
                  <option>-</option>
                </select>
                <label htmlFor="time" name="time">at</label>
                <input id="time" type="time" value={time} onChange={e => setTime(e.target.value)} />
                <button id="setbutton" className="ms-2" onClick={setInterval}>SET</button>
            </div>
          </div>
        </div>
        </>
      }
      </div>
      <footer>
        <div>&copy; GrodiTech 2024</div>
      </footer>
    </>
  );
};